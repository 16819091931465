

export default {
  name: 'RotatingLoader',
  props: {
    interval: {
      type: Number,
      default: 1500
    }
  },
  data() {
    return {
      currentIndex: 0,
      timer: null
    }
  },
  computed: {
    words() {
      return this.$t('loader')
    },
    previousWord() {
      return this.words[(this.currentIndex - 1 + this.wordsTotal) % this.wordsTotal]
    },
    currentWord() {
      return this.words[this.currentIndex]
    },
    nextWord() {
      return this.words[(this.currentIndex + 1) % this.wordsTotal]
    },
    wordsTotal() {
      return this.words.length
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.wordsTotal
    }, this.interval)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
