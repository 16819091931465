
import { mapGetters } from 'vuex'
import { mdiCloseOctagon } from '@mdi/js'
import { deleteUser } from '@/api/auth.service'
const DELETE_FLAG_NAME = 'account-deleted'

export default {
  name: 'DeleteAccountCard',
  emits: ['cancel'],
  data: () => ({
    mdiCloseOctagon,
    isLoading: false,
    isDeleted: false,
  }),
  computed: {
    ...mapGetters(['subscription']),
  },
  methods: {
    onDeleteAccount() {
      this.isLoading = true
      this.$api
        .run(deleteUser)
        .then(() => {
          this.isDeleted = true
          setTimeout(() => {
            this.$auth.logout()
            localStorage.setItem(DELETE_FLAG_NAME, true)
            this.$router.push('/')
          }, 5000)
        })
        .catch(() => {
          this.$store.dispatch(
            'setError',
            'Fehler beim Löschen des Accounts. Bitte kontaktiere den Support.'
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
