export default {
  "code": "it",
  "title": "Italiano",
  "flag": "🇮🇹",
  "meta": {
    "index": {
      "title": "Tesine migliori.\n10 volte più veloci con l'IA.",
      "description": "Senza Hesse: Stai fissando una pagina vuota per ore.\nCon Hesse: Generi ottimi testi con un semplice clic."
    },
    "profile": {
      "title": "Impostazioni del profilo",
      "description": "Controlla facilmente le impostazioni del tuo profilo"
    }
  },
  "validations": {
    "required": "{_field_} è un campo obbligatorio",
    "min": "{_field_} deve essere lungo almeno {length} caratteri",
    "max": "{_field_} non deve superare i {length} caratteri"
  },
  "buttons": {
    "close": "Chiudi",
    "upgrade": "Aggiorna",
    "get_more_words": "Ottieni più parole ora",
    "login": "Accedi",
    "logout": "Esci",
    "cancel": "Annulla",
    "yes": "Sì",
    "no": "No",
    "tryout": "Provalo",
    "add": "Aggiungi",
    "report": "Segnala",
    "save": "salva",
    "edit": "modifica",
    "copy": "Copia",
    "delete": "elimina",
    "generate": "Genera",
    "signup": "Registrati",
    "continue": "Continua",
    "submit": "Invia",
    "add_source": "Aggiungi Fonte",
    "select_source": "Seleziona Fonte",
    "new_document": "Nuovo Documento",
    "back": "indietro",
    "local_document": {
      "title": "Un documento dal mio computer",
      "type": "Carica Documento"
    },
    "web_document": {
      "title": "Hai un link a un documento?",
      "type": "Web Link/Documento"
    },
    "done": "fatto",
    "discard": "scartare"
  },
  "labels": {
    "missing_subscription": "Hai bisogno di un abbonamento per utilizzare questo strumento.",
    "booked": "il tuo piano attuale",
    "account": "Account",
    "subscription": "Abbonamento",
    "name": "Nome",
    "email": "Email",
    "limit": "Limite",
    "settings": "Impostazioni",
    "words": "Parole",
    "usage": "Utilizzo",
    "extra_words": "incl. {words} parole extra",
    "show_all_applications": "Mostra tutte le applicazioni",
    "show_all": "Mostra tutto",
    "start_page": "Pagina iniziale",
    "is_favorite": "Nei preferiti",
    "add_favorite": "Aggiungi ai preferiti",
    "wordlimit_reached": "Limite di parole raggiunto",
    "wordlimit_increase": "Aumenta il limite di parole",
    "contact": "Contatta",
    "history": "Cronologia",
    "show_history": "Mostra cronologia",
    "inprint": "Impronta",
    "dataregulation": "Regolamento dati",
    "tos": "Condizioni di utilizzo",
    "month": "mese",
    "cancel_anytime": "Annulla in qualsiasi momento",
    "try_now": "Prova gratuita",
    "book_again": "Prenota di nuovo",
    "book": "Cambia piano",
    "upgrade_to": "Aggiornamento a {product}",
    "copied_link": "Link copiato",
    "subject": "Oggetto",
    "generated_by_hesse": "Generato da Hesse in 3 secondi.",
    "use_template": "Usa modello",
    "show_mode": "Mostra altro",
    "upload": "Carica",
    "optional": "opzionale",
    "my_documents": "I Miei Documenti",
    "my_sources": "Le Mie Fonti",
    "my_projects": "I Miei Progetti",
    "add_source": "Aggiungi Fonte",
    "select_source": "Seleziona Fonte",
    "page": "Pagina {page}",
    "pages": "Pagine {from} - {to}",
    "unknown_author": "Autore sconosciuto",
    "checker_results": "Risultati del controllo IA",
    "human": "umano",
    "citation_style": "Stile di citazione",
    "started_at": "iniziato il",
    "ends_at": "finisce il",
    "cancel_subscription": "annulla abbonamento",
    "file_too_large": "Il file è troppo grande. Il limite è di 50 MB.",
    "relevance": "Rilevanza",
    "relevance_help": "La rilevanza misura quanto bene la fonte si adatta all'argomento ricercato in termini di contenuto.",
    "quality": "Qualità",
    "quality_help": "La qualità misura quanto è buona la fonte sia nel contenuto che nella forma.",
    "trustworthiness": "Affidabilità",
    "trustworthiness_help": "L'affidabilità misura, principalmente in base ai metadati della fonte, quanto essa sia attendibile.",
    "high": "alta",
    "normal": "normale",
    "low": "bassa",
    "default": "il tuo progetto attuale",
    "features": "Funzioni",
    "monthly": "Mensile",
    "year": "anno",
    "yearly": "Annuale",
    "save_50": "risparmia 50%",
    "pay_amount_per_period": "Paga {amount}/{period}.",
    "focus_of_the_chapter": "Focus del capitolo",
    "select_a_project": "Seleziona un progetto",
    "no_projects": "Nessun progetto disponibile al momento",
    "reason": "Motivo"
  },
  "blog": {
    "written_by": "Scritto da",
    "read_article": "Leggi articolo"
  },
  "source": {
    "select": {
      "empty": {
        "headline": "Qui non c'è ancora nulla.",
        "hint": "Puoi contrassegnare le fonti in 'Trova Fonti' e usarle in vari strumenti."
      }
    },
    "table": {
      "name": "Nome",
      "type": "Tipo",
      "created_at": "Creato il"
    },
    "type": {
      "document": "Documento",
      "article": "Articolo",
      "paper": "Carta"
    },
    "hint": {
      "not_usable": "Le fonti non ancora elaborate non saranno considerate durante la scrittura."
    },
    "processing": "in elaborazione...",
    "recent": "usato di recente",
    "bookmark": "segnalibro",
    "bookmarked": "segna come preferito",
    "direct_pdf_access": "Accesso diretto al PDF",
    "source_info": "Info fonte"
  },
  "placeholders": {
    "url": "https://en.wikipedia.org/wiki/Radiocarbon_dating",
    "topic": "Riforme dopo la fine della Seconda Guerra Mondiale",
    "focus_of_the_chapter_legacy": "Riforme politiche",
    "focus_of_the_chapter": "Come intitoleresti il tuo capitolo?"
  },
  "groups": {
    "research": "Ricerca",
    "essay": "Essay",
    "misc_tools": "Altri strumenti"
  },
  "flows": {
    "summarize": {
      "headline": "Sommario",
      "description": "Cosa vorresti riassumere?"
    },
    "titles": {
      "summarize_document": "Documento",
      "summarize_text": "Testo",
      "summarize_article": "Articolo",
      "summarize_source": "Fonte"
    },
    "prefill": {
      "summarize_document": "Sommario del documento."
    }
  },
  "messages": {
    "get_more_words": "Ottieni più parole ora",
    "contact_success": "Grazie per il tuo messaggio!",
    "error": "Qualcosa è andato storto. Per favore, riprova più tardi.",
    "updated": "{field} aggiornato",
    "failed_to_update": "Impossibile aggiornare \"{field}\", per favore prova di nuovo"
  },
  "validation": {
    "required": "{_field_} è un campo obbligatorio",
    "min": "{_field_} deve essere lungo almeno {length} caratteri",
    "max": "{_field_} non può superare i {length} caratteri",
    "maxarray": "{_field_} ha troppi elementi"
  },
  "domains": {
    "cancellation": {
      "headline": "Annulla abbonamento",
      "description": "Vuoi davvero annullare il tuo abbonamento? Qual è il motivo della tua cancellazione?",
      "options": [
        {
          "text": "Lavoro completato",
          "value": "work_completed"
        },
        {
          "text": "Non soddisfatto del prodotto",
          "value": "unsatisfied_with_product"
        },
        {
          "text": "Troppo costoso",
          "value": "price_performance_issue"
        },
        {
          "text": "Problemi tecnici",
          "value": "technical_issues"
        },
        {
          "text": "Ho trovato un altro fornitore",
          "value": "found_other_provider"
        },
        {
          "text": "Non sapevo fosse un abbonamento",
          "value": "unaware_of_subscription"
        },
        {
          "text": "Altro",
          "value": "other"
        }
      ]
    },
    "try": {
      "headline": "Hesse Pro",
      "loading": "Stiamo attivando il tuo account di prova..."
    },
    "openai": {
      "status": {
        "unreliable": "Al momento abbiamo un carico di server elevato. Potrebbero esserci interruzioni. Se qualcosa non funziona, riprova più tardi.",
        "working_on_fix": "Stiamo attualmente lavorando a una soluzione. Riprova più tardi."
      }
    },
    "ai_checker": {
      "hint": "I detectori AI sono strumenti che controllano i testi per il contenuto AI. Con un clic su una delle carte, si accede allo strumento corrispondente."
    },
    "cookie": {
      "headline": "Cookies 🍪",
      "text1": "Cliccando su \"Accetta tutti i cookie\", acconsenti alla memorizzazione dei cookie sul tuo dispositivo per migliorare la navigazione del sito, analizzare l'utilizzo del sito e assistere nei nostri sforzi di marketing.",
      "analytics": "Cookie di prestazione",
      "analytics_text": "Questi cookie ci permettono di analizzare anonimamente il comportamento degli utenti su Hesse.ai per ottimizzare l'esperienza utente.",
      "marketing_text": "Questi cookie possono essere impostati dai nostri partner pubblicitari attraverso il nostro sito web. Possono essere utilizzati da queste aziende per creare un profilo dei tuoi interessi e mostrarti annunci pertinenti su altri siti.",
      "marketing": "Cookie di marketing",
      "technical": "Cookie funzionali",
      "technical_text": "Questi cookie sono necessari per garantire un utilizzo sicuro e affidabile.",
      "consent": "Accetta tutti i cookie",
      "save": "Salva preferenze",
      "decline": "Rifiuta"
    },
    "write": {
      "confirm_delete_paragraph": "Vuoi eliminare davvero il paragrafo?",
      "save_changes": "Salva modifiche",
      "keep_writing": "Continua a scrivere"
    },
    "feedback": {
      "title": "Dacci un feedback aggiuntivo",
      "description": "Cerchiamo costantemente di migliorare i nostri risultati. Descrivi cosa vorresti cambiare."
    },
    "report": {
      "title": "Vuoi segnalare veramente questa risposta?",
      "reason": "Motivo della segnalazione",
      "description": "Segnala i risultati che contengono contenuti critici o illegali.",
      "result_good": "Il risultato è utile",
      "result_bad": "Il risultato non è utile"
    },
    "pricing": {
      "monthly_wordlimit": "{limit} parole al mese",
      "increase_limit": "Aumenta il limite",
      "wordlimit_reached": "Hai raggiunto il limite di parole",
      "wordlimit_reached_description": "La quota per questo mese è esaurita. Se desideri continuare, devi aumentare il limite.",
      "wordlimit_nearly_reached": "Le tue parole stanno per esaurirsi.",
      "get_more_words": "Ottieni più parole",
      "get_more_words_description": "I buoni testi richiedono molta potenza di calcolo. Questo è costoso.<br /> Ma abbiamo reso i nostri pacchetti il più economici possibile.",
      "error_title": "La prenotazione non è riuscita.",
      "error_description": "Contattaci: ti aiuteremo immediatamente.",
      "text1": "Quante sono 10.000 parole?",
      "text2": "Erano 10.000 parole",
      "referral_title": "Non riesci ancora a deciderti?",
      "referral_title2": "Hai bisogno di più parole?",
      "referral_description": "Condividi il tuo link con gli amici. Ricevi 1.000 parole gratuite per ogni nuovo utente registrato tramite il tuo link."
    },
    "survey": {
      "title": "Come valuti Hesse.ai?",
      "suggestions": "Cosa cambieresti?",
      "success_title": "Grazie mille per il tuo feedback!",
      "success_text": "Ci stai aiutando a migliorare continuamente Hesse.ai",
      "abort": "Non valutare"
    },
    "cards_hint": {
      "title": "Le schede sono disponibili ora",
      "subtitle": "Creare automaticamente <strong>schede</strong> dalla tua <strong>lettura</strong>?",
      "cta": "Crea schede ora"
    },
    "share_modal": {
      "headline": "Condividi Hesse - ottieni parole gratuite"
    },
    "login_page": {
      "loading": "Accesso in corso...",
      "error": "Il login non è riuscito",
      "delayed": "Il login sta richiedendo più tempo del solito.<br /> Controlla la tua connessione a internet e riprova se necessario.",
      "try_again": "Accedi nuovamente"
    },
    "sidebar": {
      "create_own_application": "Crea la tua applicazione",
      "hesse_cards": "Hesse Cards 🃏",
      "all_applications": "Tutte le applicazioni",
      "favourites": "I miei preferiti"
    },
    "profile": {
      "title": "Impostazioni profilo",
      "limit": "Limite",
      "used_tokens": "Token utilizzati",
      "get_more_words": "Ottieni più parole ora",
      "no_subscription": "Non hai ancora un abbonamento.",
      "upgrade_now": "Aggiorna ora",
      "manage_subscription": "Gestisci abbonamento",
      "delete_account": "Elimina account",
      "deleted": "Il tuo account è stato eliminato. Se effettui l'accesso, verrà creato un nuovo account."
    },
    "contact": {
      "title": "Contatto",
      "subtitle": "Contattaci"
    },
    "error_page": {
      "title404": "404 - Pagina non trovata",
      "title": "Si è verificato un errore",
      "text": "Aiuta a migliorare Hesse.ai.<br /> Descrivi brevemente cosa hai fatto subito prima che apparisse questa pagina.",
      "cta": "Torna alla pagina iniziale"
    },
    "success_page": {
      "loading": "Caricamento...",
      "booking_success": "La tua prenotazione è andata a buon fine!",
      "booked_product": "È stato prenotato {productName}",
      "start_now": "Inizia ora"
    }
  },
  "typewriter": {
    "writeEssay": [
      "Generazione di testi...",
      "Crittografia dei testi...",
      "Controllo del plagio...",
      "Esecuzione degli strumenti di controllo IA...",
      "Testi non più tracciabili ✔"
    ],
    "writeOutline": [
      "Generazione di testi...",
      "Crittografia dei testi...",
      "Controllo del plagio...",
      "Esecuzione degli strumenti di controllo IA...",
      "Testi non più tracciabili ✔"
    ],
    "writeIntroduction": [
      "Generazione di testi...",
      "Crittografia dei testi...",
      "Controllo del plagio...",
      "Esecuzione degli strumenti di controllo IA...",
      "Testi non più tracciabili ✔"
    ]
  },
  "project": {
    "labels": {
      "incomplete": "incompleto",
      "project": "Progetto",
      "enable_projects": "Abilita Progetti"
    },
    "create": {
      "label": "Crea nuovo progetto",
      "buttons": {
        "finish": "Finire",
        "next": "Avanti"
      },
      "welcome": {
        "title": "Benvenuto nei Progetti",
        "description": "I progetti sono un modo per organizzare la tua ricerca e scrittura. Creando un progetto, puoi mantenere il contesto della tua scrittura coerente e creare testi più velocemente.",
        "cta": "Pronto per iniziare?",
        "button": "Crea il primo progetto"
      },
      "error": {
        "title": "Errore",
        "leave": "Esci dalla creazione del progetto",
        "retry": "Riprova"
      },
      "steps": {
        "topic": {
          "enter": {
            "title": "Il Tuo Argomento",
            "description": "Per favore, inserisci il tuo argomento o idea. Può essere solo poche parole o una breve frase che cattura l'essenza di ciò di cui vuoi scrivere.",
            "label": "Inserisci qui il tuo argomento"
          },
          "assess": {
            "title": "Il Tuo Argomento",
            "description": "Valutazione del tuo argomento",
            "feedback": "Feedback: ",
            "label": "Argomento"
          }
        },
        "language": {
          "title": "Lingua",
          "label": "Lingua"
        },
        "length": {
          "title": "Requisiti di Ricerca",
          "description": "Quante pagine dovrebbe avere la tua ricerca?",
          "labels": {
            "min": "Minimo",
            "max": "Massimo"
          }
        },
        "approach": {
          "title": "Approccio alla Ricerca",
          "description": "Come desideri affrontare la tua ricerca?",
          "label": "Stile",
          "approaches": {
            "informative": "Informativo",
            "persuasive": "Persuasivo",
            "analytical": "Analitico",
            "narrative": "Narrativo"
          }
        }
      }
    },
    "delete": {
      "title": "Elimina progetto",
      "description": "Sei sicuro di voler eliminare questo progetto?"
    }
  },
  "loader": [
    "Valutando i tuoi input",
    "Aggiungendo sale e pepe",
    "Pedro, Pedro, Pedro",
    "Agitando la bacchetta magica",
    "Portando al tuo tavolo"
  ],
  "tools": {
    "findSources": "Trova Fonti",
    "writeOutline": "Creare una Struttura",
    "writeChapter": "Scrivere un capitolo",
    "writeEssay": "Scrivi un saggio"
  }
}
